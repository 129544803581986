import React, { Fragment } from "react";
import    { useState ,useEffect,useRef,forwardRef ,useImperativeHandle} from "react";
import { useLocation,useNavigate } from 'react-router-dom';
import logo from '../../jsx/components/Reports/food.jpg';
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import { Modal } from 'react-bootstrap';

const Invoice = forwardRef((props, ref) => {
 
  


  const [orderItems, setOrderItems] = useState([]);
  const [TotalAmount, setTotalAmount] = useState(0);
  const [Discount, setDiscount] = useState(0);
  const [Discount1, setDiscount1] = useState(0);
  const [report, setreport] = useState({})
  const userdata1 = localStorage.getItem('USER_data');
  const userData = JSON.parse(userdata1);
    const userid = userData.id;
    const username = userData.name;
    const [halls, setHalls] = useState([]);
    const [hallname, sethallname] = useState('')

    
  const [order, setOrder] = useState({
    //  OrderID:'',
    CustomerID: '',
    OrderDate: '',
    TotalAmount: '',
    DiscountAmount: '',
    Status: '',
    UserID: userid,
    // DeliveryID: '',
    DeliveryPersonID: '',
    DeliveryDate: '',
    DeliveryTime: '',
    DelPrice: '',
    Address: '',
    GuaranteeTypesID: '',

  });
  const [toolItems, settoolItems] = useState([]);

  useEffect(() => {
     setOrderItems(props.data2);
    settoolItems(props.data3)
    setOrder(props.data)
    console.log('props.data', props.data);

  }, [props.data, setOrder]);

     
   // const intervalId = setInterval(() => {
    //   setOrderDate(new Date());
   //  }, 1000); // Update every 1000 milliseconds (1 second)
 
     // Cleanup function to clear the interval when the component unmounts
   //  return () => clearInterval(intervalId);
   const componentRef = useRef();
   const getchalls = async () => {
    await axios.get('https://suhba.server.com.ly/back/api/getchalls ').then(({ data }) => { setHalls(data.HallName) })
  }

  const [inviteModal, setInviteModal] = useState(false);
  useImperativeHandle(ref, () => ({
    showInviteModal() {
      getchalls();
      halls.map((data) => {
        if (props.data.HallID === data.HallID) {
  sethallname(data.HallName)
         }
         console.log('hallname',hallname)

      });
      setInviteModal(true);
    }
  }));
  const generatePDF = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Userdata",
    onAfterPrint: () => alert("Data saved in PDF")
  });
  console.log(orderItems)
  return (
    <Fragment>
        <Modal className="modal fade" id="exampleModal1" show={inviteModal} onHide={() => setInviteModal(false)} centered>
        <div className="modal-content">
        
             
              <div className="modal-body" ref={componentRef}  dir="rtl">
              <div className="row">
              <div className="col-xl-12" > 
                <center><img src={logo} alt="Logo" className="logo" style={{ height: "100px", width: "150px", textAlign: "center" }} /></center>
                <div className="shop-info">
                  <h2 style={{ textAlign: "center" }}>صحبة طببة</h2>
                </div>
              </div>
            </div>
                 
                <div className="table-responsive">
                <table className="table table-bordered verticle-middle table-responsive-sm" style={{ marginBottom:'0px',borderCollapse: "unset",padding:"0px", borderWidth: '1 px' }}>
                <tbody>
                <tr style={{width:'100%'}}>
                    <td style={{ boxShadow: '0 1px 1px 0 black',border: '1px solid #ccc' }}>الزبون  :{order.CustomerName}</td>
                    <td style={{ boxShadow: '0 1px 1px 0 black' , border: '1px solid #ccc' }}>الهاتف:{order.PhoneNumber} - {order.PhoneNumber1} - {order.PhoneNumber2} </td>
                    
                  </tr>
                  <tr style={{width:'100%'}}> 
                    <td style={{ boxShadow: '0 1px 1px 0 black' , border: '1px solid #ccc' }}>العنوان:{hallname}</td>
                    <td style={{ boxShadow: '0 1px 1px 0 black',border: '1px solid #ccc' }}> تفاصيل العنوان :{order.Address}</td>
                  </tr>
                  <tr style={{width:'100%'}}> 
                    <td style={{ boxShadow: '0 1px 1px 0 black' , border: '1px solid #ccc' }}>باقي القيمة:{(parseFloat(order.TotalAmount)- parseFloat(order.Prepay))}</td>
                    <td style={{ boxShadow: '0 1px 1px 0 black',border: '1px solid #ccc' }}>توقيت الطلبية :{order.DeliveryTime}</td>
                  </tr>
                  </tbody>
                  </table>
                  <table className="table table-bordered verticle-middle table-responsive-sm" style={{marginBottom:'0px',marginTop:"1px" ,padding:"0px"}}>
                    <thead>
                    
                      <tr>
                        <th className="center">#</th>
                        <th>الصنف</th>
                        <th className="center">الكمية</th>
                        <th className="center">الإضافات</th>

                      </tr>
                    </thead>
                    <tbody>
                  
                      {orderItems.map((item, ind)=>(
                                                    <tr className="center" key={ind}>
                                                        <td className="center">{ind}
                                                       </td>
                                                        <td className="left strong">{item.Name}  
                                                       </td>	
                                                        
                                                       <td className="right">{item.Quantity}</td>
                                                       <td className="right">{item.extension}</td>

                                                        
                                                    </tr>  
                                                ))}
                       
                 
                    </tbody>
                  </table>
                  <div className="col-xl-12">
<p>إعد بواسطة :  {username}</p>
<center><p>الختم</p></center>
    </div> 
                </div>
                
              </div>
              </div>
         <div className="modal-footer">
            <button  className="btn btn-primary m-2" onClick={generatePDF}>طباعه التقرير</button>
          </div>  
          </Modal>
  
    </Fragment>
  );
});

export default Invoice;
